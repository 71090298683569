/*
	  This will hide the focus indicator if the element receives focus via the mouse,
	  but it will still show up on keyboard focus.
	  More information at: https://github.com/WICG/focus-visible
	*/
html .tooltip_plain-button__38dT8:focus:not(:focus-visible) {
  outline: none; }

html .tooltip_plain-button__38dT8::-moz-focus-inner {
  border: 0; }

.tooltip_reference-wrapper__2gyMI {
  display: inline-block; }

.tooltip_reference-anchor__kIdIz {
  display: inline-block; }

.tooltip_plain-button__38dT8 {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  line-height: 0;
  vertical-align: middle;
  transition: var(--animation--fast); }
  .tooltip_plain-button__38dT8:focus-visible {
    outline: 0;
    box-shadow: var(--shadow--focus--default);
    border-radius: var(--border-radius--small); }

.tooltip_text-and-icon-wrapper___cnkU {
  display: inline-flex;
  align-items: center; }
  .tooltip_text-and-icon-wrapper___cnkU > button {
    margin-left: var(--space--xx-small); }

.tooltip_tooltip__hpbL9 {
  display: inline-block;
  padding: var(--space--xx-small) var(--space--x-small);
  border-radius: var(--border-radius--medium);
  background-color: var(--color--gray--darker);
  box-shadow: var(--shadow--layer--medium);
  white-space: pre-wrap;
  width: max-content;
  letter-spacing: initial;
  text-align: initial; }

.tooltip_tooltip-text__w2beD.tooltip_tooltip-text__w2beD {
  display: inline-block;
  max-width: 48ch;
  overflow-wrap: break-word; }

.tooltip_tooltip-arrow__2vs0n {
  position: absolute;
  width: var(--space--x-small);
  height: var(--space--x-small);
  background-color: var(--color--gray--darker);
  box-shadow: 0 var(--space--xx-small) var(--space--x-small) calc(var(--space--xx-small) * -1) rgba(31, 43, 63, 0.12), 0 0.125rem var(--space--xx-small) calc(var(--space--xx-small) * -1) rgba(31, 43, 63, 0.08); }
