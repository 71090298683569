.heading_h1__1pyzg {
  font-family: var(--font-family--heading);
  word-break: break-word;
  margin: 0;
  text-align: left;
  line-height: var(--line-height--reset);
  font-size: var(--font-size--xxx-large);
  font-weight: var(--font-weight--medium);
  color: var(--color--text--static--header); }
  .heading_h1__1pyzg:not(.heading_line-height-single-line__227Y8) {
    line-height: var(--line-height--x-small); }

.typography-text-container > .heading_h1__1pyzg {
  margin-bottom: var(--space--xxx-large); }

.typography-text-container.typography-article-container > .heading_h1__1pyzg {
  font-weight: var(--font-weight--bold); }

.heading_h2__1AL1u {
  font-family: var(--font-family--heading);
  word-break: break-word;
  margin: 0;
  text-align: left;
  line-height: var(--line-height--reset);
  font-size: var(--font-size--large);
  font-weight: var(--font-weight--bold);
  color: var(--color--text--static--header); }
  .heading_h2__1AL1u:not(.heading_line-height-single-line__227Y8) {
    line-height: var(--line-height--small); }

.typography-text-container > .heading_h2__1AL1u {
  font-size: var(--font-size--x-large);
  margin-top: var(--space--xxx-large);
  margin-bottom: var(--space--medium); }

.typography-text-container.typography-article-container > .heading_h2__1AL1u {
  font-size: var(--font-size--xx-large);
  margin-bottom: var(--space--large); }

.heading_h3__VGjcH {
  font-family: var(--font-family--heading);
  word-break: break-word;
  margin: 0;
  text-align: left;
  line-height: var(--line-height--reset);
  font-size: var(--font-size--large);
  font-weight: var(--font-weight--medium);
  color: var(--color--text--static--header); }
  .heading_h3__VGjcH:not(.heading_line-height-single-line__227Y8) {
    line-height: var(--line-height--small); }

.typography-text-container > .heading_h3__VGjcH {
  margin-top: var(--space--medium);
  margin-bottom: var(--space--small); }

.typography-text-container.typography-article-container > .heading_h3__VGjcH {
  font-size: var(--font-size--x-large);
  margin-top: var(--space--x-large);
  margin-bottom: var(--space--medium); }

.heading_h4__2UHWD {
  font-family: var(--font-family--heading);
  word-break: break-word;
  margin: 0;
  text-align: left;
  line-height: var(--line-height--reset);
  font-size: var(--font-size--medium);
  font-weight: var(--font-weight--medium);
  color: var(--color--text--static--header); }
  .heading_h4__2UHWD:not(.heading_line-height-single-line__227Y8) {
    line-height: var(--line-height--medium); }

.typography-text-container > .heading_h4__2UHWD {
  line-height: var(--line-height--medium);
  margin-top: var(--space--medium);
  margin-bottom: var(--space--xx-small); }

.typography-text-container.typography-article-container > .heading_h4__2UHWD {
  font-size: var(--font-size--large);
  margin-top: var(--space--large);
  margin-bottom: var(--space--x-small); }

.heading_h5__3xG9Q {
  font-family: var(--font-family--heading);
  word-break: break-word;
  margin: 0;
  text-align: left;
  line-height: var(--line-height--reset);
  font-size: var(--font-size--small);
  font-weight: var(--font-weight--normal);
  color: var(--color--text--static--header);
  letter-spacing: 0.0625rem;
  text-transform: uppercase; }
  .heading_h5__3xG9Q:not(.heading_line-height-single-line__227Y8) {
    line-height: var(--line-height--small); }

.typography-text-container > .heading_h5__3xG9Q {
  line-height: var(--line-height--small);
  margin-top: var(--space--small);
  margin-bottom: var(--space--xx-small); }

.typography-text-container.typography-article-container > .heading_h5__3xG9Q {
  font-size: var(--font-size--medium);
  margin-top: var(--space--medium);
  margin-bottom: var(--space--xx-small); }

.heading_h6__JKvhj {
  font-family: var(--font-family--heading);
  word-break: break-word;
  margin: 0;
  text-align: left;
  line-height: var(--line-height--reset);
  font-size: var(--font-size--small);
  font-weight: var(--font-weight--bold);
  color: var(--color--text--static--header);
  font-family: var(--font-family--default); }
  .heading_h6__JKvhj:not(.heading_line-height-single-line__227Y8) {
    line-height: var(--line-height--medium); }

.typography-text-container > .heading_h6__JKvhj {
  line-height: var(--line-height--medium);
  margin-top: var(--space--small);
  margin-bottom: var(--space--xx-small); }

.typography-text-container.typography-article-container > .heading_h6__JKvhj {
  font-size: var(--font-size--medium);
  line-height: var(--line-height--large);
  margin-top: var(--space--medium);
  margin-bottom: var(--space--xx-small); }

.typography-text-container > .heading_h1__1pyzg:first-child,
.typography-text-container > .heading_h2__1AL1u:first-child,
.typography-text-container > .heading_h3__VGjcH:first-child,
.typography-text-container > .heading_h4__2UHWD:first-child,
.typography-text-container > .heading_h5__3xG9Q:first-child,
.typography-text-container > .heading_h6__JKvhj:first-child,
.typography-text-container.typography-article-container > .heading_h1__1pyzg:first-child,
.typography-text-container.typography-article-container > .heading_h2__1AL1u:first-child,
.typography-text-container.typography-article-container > .heading_h3__VGjcH:first-child,
.typography-text-container.typography-article-container > .heading_h4__2UHWD:first-child,
.typography-text-container.typography-article-container > .heading_h5__3xG9Q:first-child,
.typography-text-container.typography-article-container > .heading_h6__JKvhj:first-child {
  margin-top: 0; }
