/*
	  This will hide the focus indicator if the element receives focus via the mouse,
	  but it will still show up on keyboard focus.
	  More information at: https://github.com/WICG/focus-visible
	*/
html .card_card__1HxFT:focus:not(:focus-visible) {
  outline: none; }

html .card_card__1HxFT::-moz-focus-inner {
  border: 0; }

.card_card__1HxFT {
  background: var(--color--background--static--primary);
  border-radius: var(--border-radius--medium);
  box-shadow: var(--shadow--layer--low);
  overflow: hidden; }
  .card_card__1HxFT:focus {
    box-shadow: var(--shadow--layer--low); }
    .card_card__1HxFT:focus:focus-visible {
      box-shadow: inset 0 0 0 0.125rem var(--color--blue); }

.card_card__1HxFT + .card_card__1HxFT {
  margin-top: var(--space--medium); }

.card_card-header__2-tKS {
  position: relative;
  padding: var(--space--medium);
  border-bottom: 1px solid var(--color--border--separator); }

.card_card-footer__15Wcp {
  overflow-y: hidden;
  border-bottom-left-radius: var(--border-radius--medium);
  border-bottom-right-radius: var(--border-radius--medium); }
