.paragraph_line-height-single-line__1ofCT {
  line-height: var(--line-height--reset) !important; }

.paragraph_alignment-left__iVXyc {
  text-align: left !important; }

.paragraph_alignment-center__3R7-r {
  text-align: center !important; }

.paragraph_alignment-right__dbKDr {
  text-align: right !important; }

.paragraph_size-x-small__cCip8 {
  font-size: var(--font-size--x-small) !important; }
  .paragraph_size-x-small__cCip8:not(.paragraph_line-height-single-line__1ofCT) {
    line-height: var(--line-height--small) !important; }

.paragraph_size-small__2W2xl {
  font-size: var(--font-size--small) !important; }
  .paragraph_size-small__2W2xl:not(.paragraph_line-height-single-line__1ofCT) {
    line-height: var(--line-height--medium) !important; }

.paragraph_size-medium__3rZ5x {
  font-size: var(--font-size--medium) !important; }
  .paragraph_size-medium__3rZ5x:not(.paragraph_line-height-single-line__1ofCT) {
    line-height: var(--line-height--large) !important; }

.paragraph_size-large__OpSm5 {
  font-size: var(--font-size--large) !important; }
  .paragraph_size-large__OpSm5:not(.paragraph_line-height-single-line__1ofCT) {
    line-height: var(--line-height--medium) !important; }

.paragraph_size-x-large__3Bh6m {
  font-size: var(--font-size--x-large) !important; }
  .paragraph_size-x-large__3Bh6m:not(.paragraph_line-height-single-line__1ofCT) {
    line-height: var(--line-height--medium) !important; }

.paragraph_size-xx-large__3bSWv {
  font-size: var(--font-size--xx-large) !important; }
  .paragraph_size-xx-large__3bSWv:not(.paragraph_line-height-single-line__1ofCT) {
    line-height: var(--line-height--medium) !important; }

.paragraph_size-xxx-large__XOslb {
  font-size: var(--font-size--xxx-large) !important; }
  .paragraph_size-xxx-large__XOslb:not(.paragraph_line-height-single-line__1ofCT) {
    line-height: var(--line-height--small) !important; }

.paragraph_tone-neutral-dark__PG-U_ {
  color: var(--color--gray--darker) !important; }

.paragraph_tone-neutral-light__1aR4l {
  color: var(--color--white) !important; }

.paragraph_tone-subtle__1EIL- {
  color: var(--color--typography--tone--subtle) !important; }

.paragraph_tone-positive__3NmuR {
  color: var(--color--typography--tone--positive) !important; }

.paragraph_tone-negative__11hwA {
  color: var(--color--typography--tone--negative) !important; }

.paragraph_tone-warning__3u0tr {
  color: var(--color--typography--tone--warning) !important; }

.paragraph_emphasis-normal__3epqY {
  font-weight: var(--font-weight--normal) !important; }

.paragraph_emphasis-medium__XriYe {
  font-weight: var(--font-weight--medium) !important; }

.paragraph_emphasis-strong__3Q_Ci {
  font-weight: var(--font-weight--bold) !important; }

.paragraph_paragraph__20FFh {
  font-family: var(--font-family--default);
  font-size: var(--font-size--small);
  font-weight: var(--font-weight--normal);
  color: var(--color--text--static--body);
  margin: 0;
  line-height: var(--line-height--medium); }

.typography-text-container > .paragraph_paragraph__20FFh {
  line-height: var(--line-height--medium); }
  .typography-text-container > .paragraph_paragraph__20FFh + .paragraph_paragraph__20FFh {
    margin-top: var(--space--small); }

.typography-text-container.typography-article-container > .paragraph_paragraph__20FFh {
  font-size: var(--font-size--medium);
  line-height: var(--line-height--large); }
  .typography-text-container.typography-article-container > .paragraph_paragraph__20FFh + .paragraph_paragraph__20FFh {
    margin-top: var(--space--medium); }
