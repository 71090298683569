.badge_badge__37C_f {
  display: inline-flex;
  align-items: center;
  padding: var(--space--xx-small) var(--space--x-small);
  margin: calc(var(--space--xx-small) / 2);
  white-space: nowrap;
  border-radius: var(--border-radius--small); }
  .badge_badge__37C_f:empty {
    display: none; }
  .badge_badge__37C_f p {
    color: var(--color--white); }
  .badge_badge__37C_f.badge_positive__3mvTs.badge_dark__ZwoLe {
    background-color: var(--color--status--positive--dark); }
  .badge_badge__37C_f.badge_positive__3mvTs.badge_light__1kmko {
    background-color: var(--color--status--positive--light); }
    .badge_badge__37C_f.badge_positive__3mvTs.badge_light__1kmko p {
      color: var(--color--status--positive--light--foreground); }
  .badge_badge__37C_f.badge_neutral__3K9Dd.badge_dark__ZwoLe {
    background-color: var(--color--status--neutral--dark); }
  .badge_badge__37C_f.badge_neutral__3K9Dd.badge_light__1kmko {
    background-color: var(--color--status--neutral--light); }
    .badge_badge__37C_f.badge_neutral__3K9Dd.badge_light__1kmko p {
      color: var(--color--status--neutral--light--foreground); }
  .badge_badge__37C_f.badge_warning__3u6nN.badge_dark__ZwoLe {
    background-color: var(--color--status--warning--neutral); }
    .badge_badge__37C_f.badge_warning__3u6nN.badge_dark__ZwoLe p {
      color: var(--color--status--warning--light--foreground); }
  .badge_badge__37C_f.badge_warning__3u6nN.badge_light__1kmko {
    background-color: var(--color--status--warning--light); }
    .badge_badge__37C_f.badge_warning__3u6nN.badge_light__1kmko p {
      color: var(--color--status--warning--light--foreground); }
  .badge_badge__37C_f.badge_negative__3Kf76.badge_dark__ZwoLe {
    background-color: var(--color--status--negative--dark); }
  .badge_badge__37C_f.badge_negative__3Kf76.badge_light__1kmko {
    background-color: var(--color--status--negative--light); }
    .badge_badge__37C_f.badge_negative__3Kf76.badge_light__1kmko p {
      color: var(--color--status--negative--light--foreground); }
  .badge_badge__37C_f.badge_subtle__E17JI.badge_dark__ZwoLe {
    background-color: var(--color--status--subtle--dark); }
  .badge_badge__37C_f.badge_subtle__E17JI.badge_light__1kmko {
    background-color: var(--color--status--subtle--light); }
    .badge_badge__37C_f.badge_subtle__E17JI.badge_light__1kmko p {
      color: var(--color--status--subtle--light--foreground); }
  .badge_badge__37C_f.badge_highlight1__315nT.badge_dark__ZwoLe {
    background-color: var(--color--status--highlight1--dark); }
  .badge_badge__37C_f.badge_highlight1__315nT.badge_light__1kmko {
    background-color: var(--color--status--highlight1--light); }
    .badge_badge__37C_f.badge_highlight1__315nT.badge_light__1kmko p {
      color: var(--color--status--highlight1--light--foreground); }
  .badge_badge__37C_f.badge_highlight2__3mEW2.badge_dark__ZwoLe {
    background-color: var(--color--status--highlight2--dark); }
  .badge_badge__37C_f.badge_highlight2__3mEW2.badge_light__1kmko {
    background-color: var(--color--status--highlight2--light); }
    .badge_badge__37C_f.badge_highlight2__3mEW2.badge_light__1kmko p {
      color: var(--color--pink--darker); }
  .badge_badge__37C_f.badge_highlight3__9XRok.badge_dark__ZwoLe {
    background-color: var(--color--status--highlight3--dark); }
  .badge_badge__37C_f.badge_highlight3__9XRok.badge_light__1kmko {
    background-color: var(--color--status--highlight3--light); }
    .badge_badge__37C_f.badge_highlight3__9XRok.badge_light__1kmko p {
      color: var(--color--purple--dark); }
