@keyframes spinner_spin__1ioo_ {
  to {
    transform: rotate(360deg); } }

@keyframes spinner_fadeIn__3WUxD {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.spinner_spinner__1FeEa {
  margin: auto;
  border: 0.125rem solid color-mix(in srgb, var(--color--white) 50%, transparent);
  border-top-color: var(--color--white);
  border-radius: 50%;
  opacity: 0;
  animation: spinner_spin__1ioo_ 0.7s linear infinite, spinner_fadeIn__3WUxD 0.4s ease 1 forwards;
  forced-color-adjust: none; }

.spinner_static-fallback-icon__1qHFV {
  display: none; }

@media (prefers-reduced-motion: reduce) {
  .spinner_spinner__1FeEa {
    border: unset;
    border-radius: unset;
    animation: unset;
    opacity: 1; }
  .spinner_static-fallback-icon__1qHFV {
    display: unset; } }

.spinner_small__1qveY,
.spinner_small__1qveY .spinner_static-fallback-icon__1qHFV {
  width: var(--size--icon--small);
  height: var(--size--icon--small); }

.spinner_medium__2D_5j,
.spinner_medium__2D_5j .spinner_static-fallback-icon__1qHFV {
  width: var(--size--icon--default);
  height: var(--size--icon--default); }

.spinner_large__3MYN1,
.spinner_large__3MYN1 .spinner_static-fallback-icon__1qHFV {
  width: 3rem;
  height: 3rem;
  border-width: var(--space--xx-small); }

.spinner_light__c7f7n {
  border-color: color-mix(in srgb, var(--color--white) 50%, transparent);
  border-top-color: var(--color--white); }
  .spinner_light__c7f7n .spinner_static-fallback-icon__1qHFV {
    fill: var(--color--white); }

.spinner_dark__2ZKgJ {
  border-color: color-mix(in srgb, var(--color--blue) 20%, transparent);
  border-top-color: var(--color--blue); }
  .spinner_dark__2ZKgJ .spinner_static-fallback-icon__1qHFV {
    fill: var(--color--blue); }
