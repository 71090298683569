.grid_grid__Tt2WE {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start; }

.grid_grid-section__twOGg {
  padding: var(--space--x-small); }

.grid_full__1FVmA {
  width: 100%; }

.grid_threefourths__2zrtr {
  width: 75%; }
  @media (max-width: 75rem) {
    .grid_threefourths__2zrtr {
      width: 100%; } }

.grid_twothirds__3uuee {
  width: 66.66%; }
  @media (max-width: 75rem) {
    .grid_twothirds__3uuee {
      width: 100%; } }

.grid_half__vybii {
  width: 50%; }
  @media (max-width: 75rem) {
    .grid_half__vybii {
      width: 100%; } }

.grid_third__2UGBu {
  width: 33.33%; }
  @media (max-width: 75rem) {
    .grid_third__2UGBu {
      width: 100%; } }

.grid_fourth__2NMqQ {
  width: 25%; }
  @media (max-width: 75rem) {
    .grid_fourth__2NMqQ {
      width: 50%; } }
  @media (max-width: 62rem) {
    .grid_fourth__2NMqQ {
      width: 100%; } }
