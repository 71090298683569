.icons_small__1DGin {
  width: var(--size--icon--small);
  height: var(--size--icon--small); }

.icons_medium__24QB3 {
  width: var(--size--icon--medium);
  height: var(--size--icon--medium); }

.icons_large__2Tr18 {
  width: var(--size--icon--default);
  height: var(--size--icon--default); }

.icons_responsive__37dtF {
  width: var(--size--icon--responsive);
  height: var(--size--icon--responsive); }

.icons_deg0__9gBQO {
  transition: var(--animation--fast);
  transform: rotate(0deg); }

.icons_deg90__2o_2L {
  transition: var(--animation--fast);
  transform: rotate(90deg); }

.icons_deg180__13qhn {
  transition: var(--animation--fast);
  transform: rotate(180deg); }

.icons_deg270__2W7Nc {
  transition: var(--animation--fast);
  transform: rotate(270deg); }
