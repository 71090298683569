.list_ol__1MVWm {
  font-family: var(--font-family--default);
  font-size: var(--font-size--small);
  font-weight: var(--font-weight--normal);
  color: var(--color--text--static--body);
  line-height: var(--line-height--medium);
  padding: 0;
  margin: 0;
  list-style: outside; }

.typography-text-container > .list_ol__1MVWm,
.typography-text-container > .list_ol__1MVWm > li > .list_ol__1MVWm,
.typography-text-container > .list_ol__1MVWm > li > .list_ul__FU7Mq {
  margin-top: var(--space--x-small);
  margin-bottom: var(--space--small); }

.typography-text-container > .list_ol__1MVWm:first-child {
  margin-top: 0; }

.typography-text-container.typography-article-container > .list_ol__1MVWm,
.typography-text-container.typography-article-container > .list_ol__1MVWm > li > .list_ol__1MVWm,
.typography-text-container.typography-article-container > .list_ol__1MVWm > li > .list_ul__FU7Mq {
  font-size: var(--font-size--medium);
  line-height: var(--line-height--large);
  margin-top: var(--space--small);
  margin-bottom: var(--space--medium); }

.typography-text-container.typography-article-container > .list_ol__1MVWm:first-child {
  margin-top: 0; }

.list_ol__1MVWm {
  list-style-type: decimal; }
  .list_ol__1MVWm .list_li__FALBr {
    margin: var(--space--xx-small);
    margin-left: var(--space--medium); }

.list_ul__FU7Mq {
  font-family: var(--font-family--default);
  font-size: var(--font-size--small);
  font-weight: var(--font-weight--normal);
  color: var(--color--text--static--body);
  line-height: var(--line-height--medium);
  padding: 0;
  margin: 0;
  list-style: outside; }

.typography-text-container > .list_ul__FU7Mq,
.typography-text-container > .list_ul__FU7Mq > li > .list_ul__FU7Mq,
.typography-text-container > .list_ul__FU7Mq > li > .list_ol__1MVWm {
  margin-top: var(--space--x-small);
  margin-bottom: var(--space--small); }

.typography-text-container > .list_ul__FU7Mq:first-child {
  margin-top: 0; }

.typography-text-container.typography-article-container > .list_ul__FU7Mq,
.typography-text-container.typography-article-container > .list_ul__FU7Mq > li > .list_ul__FU7Mq,
.typography-text-container.typography-article-container > .list_ul__FU7Mq > li > .list_ol__1MVWm {
  font-size: var(--font-size--medium);
  line-height: var(--line-height--large);
  margin-top: var(--space--small);
  margin-bottom: var(--space--medium); }

.typography-text-container.typography-article-container > .list_ul__FU7Mq:first-child {
  margin-top: 0; }

.list_ul__FU7Mq .list_li__FALBr {
  margin: var(--space--xx-small);
  margin-left: var(--space--medium); }
