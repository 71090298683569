/*
	  This will hide the focus indicator if the element receives focus via the mouse,
	  but it will still show up on keyboard focus.
	  More information at: https://github.com/WICG/focus-visible
	*/
html .link_link-container__1mNjg:focus:not(:focus-visible) {
  outline: none; }

html .link_link-container__1mNjg::-moz-focus-inner {
  border: 0; }

.link_link-container__1mNjg {
  display: inline-block;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  line-height: inherit;
  text-decoration: inherit; }
  .link_link-container__1mNjg.link_inline-link__1udkX .link_link-text__2hPRR {
    text-decoration: underline;
    font-size: inherit;
    line-height: inherit; }
    .link_link-container__1mNjg.link_inline-link__1udkX .link_link-text__2hPRR .link_link-indicator__3VZqL {
      display: none; }
  .link_link-container__1mNjg:hover .link_link-text__2hPRR {
    text-decoration: underline;
    color: var(--color--blue--darker); }
  .link_link-container__1mNjg:active .link_link-text__2hPRR {
    text-decoration: underline;
    color: var(--color--blue--darkest); }
  .link_link-container__1mNjg:focus-visible {
    box-shadow: 0 0 0 0.125rem var(--color--blue);
    outline: none; }
  .link_link-container__1mNjg.link_open-new__3GhD- .link_link-text__2hPRR .link_link-indicator__3VZqL {
    top: 0; }
    .link_link-container__1mNjg.link_open-new__3GhD- .link_link-text__2hPRR .link_link-indicator__3VZqL .link_up-arrow__1XQtM {
      display: inherit; }
    .link_link-container__1mNjg.link_open-new__3GhD- .link_link-text__2hPRR .link_link-indicator__3VZqL .link_straight-arrow__11nRV {
      display: none; }

.link_link-text__2hPRR {
  color: var(--color--blue);
  font-weight: var(--font-weight--interactive--default);
  text-decoration: none;
  word-break: break-word; }
  .link_link-text__2hPRR.link_disable-word-break__1wWGK {
    word-break: unset; }
  .link_link-text__2hPRR.link_small__3m9Kd {
    font-size: var(--font-size--x-small);
    line-height: var(--line-height--small); }
  .link_link-text__2hPRR.link_medium__3GDui {
    font-size: var(--font-size--interactive--default);
    line-height: var(--line-height--medium); }
  .link_link-text__2hPRR.link_large__1EzlY {
    font-size: var(--font-size--medium);
    line-height: var(--line-height--large); }
  .link_link-text__2hPRR .link_link-indicator__3VZqL {
    display: inline-block;
    position: relative;
    top: -0.0625rem; }
    .link_link-text__2hPRR .link_link-indicator__3VZqL .link_up-arrow__1XQtM {
      display: none; }
    .link_link-text__2hPRR .link_link-indicator__3VZqL .link_straight-arrow__11nRV {
      display: inherit; }
  .link_link-text__2hPRR.link_unstyled__3wARk {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-style: inherit;
    line-height: inherit;
    text-decoration: inherit; }
