.common_centered-small__2Zi6O {
  max-width: 37.5rem;
  width: 100%;
  margin: auto; }

.common_centered-medium__28bnP {
  max-width: 56.25rem;
  width: 100%;
  margin: auto; }

.common_gap-xx-small__cLSm3 {
  gap: var(--space--xx-small); }

.common_gap-x-small__2Ahtj {
  gap: var(--space--x-small); }

.common_gap-small__3QRDc {
  gap: var(--space--small); }

.common_gap-medium__tUcTJ {
  gap: var(--space--medium); }

.common_gap-large__1pIXk {
  gap: var(--space--x-large); }
