.content_content__2X6M4.content_padding-small__2D4LT {
  padding: var(--space--xx-small); }

.content_content__2X6M4.content_padding-compact__3hTQY {
  padding: var(--space--x-small) var(--space--medium); }

.content_content__2X6M4.content_padding-medium__1poqx {
  padding: var(--space--medium); }

.content_content__2X6M4.content_padding-large__2C5AW {
  padding: var(--space--xx-large); }

.content_layout__19SPP {
  display: flex;
  width: 100%; }
  .content_layout__19SPP.content_padding-small__2D4LT {
    padding: var(--space--xx-small); }
  .content_layout__19SPP.content_padding-medium__1poqx {
    padding: var(--space--x-small); }
  .content_layout__19SPP.content_padding-large__2C5AW {
    padding: var(--space--medium); }

@media (max-width: 62rem) {
  .content_layout__19SPP {
    flex-direction: column; } }
