.typography_line-height-single-line__2767d {
  line-height: var(--line-height--reset) !important; }

.typography_alignment-left__1y_Qf {
  text-align: left !important; }

.typography_alignment-center__1E0Hh {
  text-align: center !important; }

.typography_alignment-right__2JdWO {
  text-align: right !important; }

.typography_size-x-small__PFX8T {
  font-size: var(--font-size--x-small) !important; }
  .typography_size-x-small__PFX8T:not(.typography_line-height-single-line__2767d) {
    line-height: var(--line-height--small) !important; }

.typography_size-small__3a1Y4 {
  font-size: var(--font-size--small) !important; }
  .typography_size-small__3a1Y4:not(.typography_line-height-single-line__2767d) {
    line-height: var(--line-height--medium) !important; }

.typography_size-medium__2Z9V_ {
  font-size: var(--font-size--medium) !important; }
  .typography_size-medium__2Z9V_:not(.typography_line-height-single-line__2767d) {
    line-height: var(--line-height--large) !important; }

.typography_size-large__2Hrr2 {
  font-size: var(--font-size--large) !important; }
  .typography_size-large__2Hrr2:not(.typography_line-height-single-line__2767d) {
    line-height: var(--line-height--medium) !important; }

.typography_size-x-large__oOUCD {
  font-size: var(--font-size--x-large) !important; }
  .typography_size-x-large__oOUCD:not(.typography_line-height-single-line__2767d) {
    line-height: var(--line-height--medium) !important; }

.typography_size-xx-large__3t3pN {
  font-size: var(--font-size--xx-large) !important; }
  .typography_size-xx-large__3t3pN:not(.typography_line-height-single-line__2767d) {
    line-height: var(--line-height--medium) !important; }

.typography_size-xxx-large__XrdNO {
  font-size: var(--font-size--xxx-large) !important; }
  .typography_size-xxx-large__XrdNO:not(.typography_line-height-single-line__2767d) {
    line-height: var(--line-height--small) !important; }

.typography_tone-neutral-dark__c5_u9 {
  color: var(--color--gray--darker) !important; }

.typography_tone-neutral-light__3vdV4 {
  color: var(--color--white) !important; }

.typography_tone-subtle__3rePe {
  color: var(--color--typography--tone--subtle) !important; }

.typography_tone-positive__31EL7 {
  color: var(--color--typography--tone--positive) !important; }

.typography_tone-negative__3T_tb {
  color: var(--color--typography--tone--negative) !important; }

.typography_tone-warning__1GeIL {
  color: var(--color--typography--tone--warning) !important; }

.typography_emphasis-normal__cYnAj {
  font-weight: var(--font-weight--normal) !important; }

.typography_emphasis-medium__2Wms6 {
  font-weight: var(--font-weight--medium) !important; }

.typography_emphasis-strong__1Vw-G {
  font-weight: var(--font-weight--bold) !important; }
