.base-popover_popper-wrapper__cwz1k {
  display: inline;
  z-index: 1500;
  transition: opacity var(--animation--fast);
  opacity: 0;
  pointer-events: none; }
  .base-popover_popper-wrapper__cwz1k.base-popover_show__1SjTg {
    opacity: 1;
    pointer-events: all; }
  .base-popover_popper-wrapper__cwz1k.base-popover_unMounted__3M5PA {
    height: 0; }
