.inline-text_line-height-single-line__2gr5G {
  line-height: var(--line-height--reset) !important; }

.inline-text_alignment-left__18Cil {
  text-align: left !important; }

.inline-text_alignment-center__1Nu2W {
  text-align: center !important; }

.inline-text_alignment-right__12XH4 {
  text-align: right !important; }

.inline-text_size-x-small__fdj2K {
  font-size: var(--font-size--x-small) !important; }
  .inline-text_size-x-small__fdj2K:not(.inline-text_line-height-single-line__2gr5G) {
    line-height: var(--line-height--small) !important; }

.inline-text_size-small__NNQs3 {
  font-size: var(--font-size--small) !important; }
  .inline-text_size-small__NNQs3:not(.inline-text_line-height-single-line__2gr5G) {
    line-height: var(--line-height--medium) !important; }

.inline-text_size-medium__ksva4 {
  font-size: var(--font-size--medium) !important; }
  .inline-text_size-medium__ksva4:not(.inline-text_line-height-single-line__2gr5G) {
    line-height: var(--line-height--large) !important; }

.inline-text_size-large__3MDRS {
  font-size: var(--font-size--large) !important; }
  .inline-text_size-large__3MDRS:not(.inline-text_line-height-single-line__2gr5G) {
    line-height: var(--line-height--medium) !important; }

.inline-text_size-x-large__A2M7N {
  font-size: var(--font-size--x-large) !important; }
  .inline-text_size-x-large__A2M7N:not(.inline-text_line-height-single-line__2gr5G) {
    line-height: var(--line-height--medium) !important; }

.inline-text_size-xx-large__3FnfF {
  font-size: var(--font-size--xx-large) !important; }
  .inline-text_size-xx-large__3FnfF:not(.inline-text_line-height-single-line__2gr5G) {
    line-height: var(--line-height--medium) !important; }

.inline-text_size-xxx-large__1xPx2 {
  font-size: var(--font-size--xxx-large) !important; }
  .inline-text_size-xxx-large__1xPx2:not(.inline-text_line-height-single-line__2gr5G) {
    line-height: var(--line-height--small) !important; }

.inline-text_tone-neutral-dark__20q0L {
  color: var(--color--gray--darker) !important; }

.inline-text_tone-neutral-light__2pWvu {
  color: var(--color--white) !important; }

.inline-text_tone-subtle__3EZa1 {
  color: var(--color--typography--tone--subtle) !important; }

.inline-text_tone-positive__1fni6 {
  color: var(--color--typography--tone--positive) !important; }

.inline-text_tone-negative__3VOmw {
  color: var(--color--typography--tone--negative) !important; }

.inline-text_tone-warning__i5Caz {
  color: var(--color--typography--tone--warning) !important; }

.inline-text_emphasis-normal__3MsR0 {
  font-weight: var(--font-weight--normal) !important; }

.inline-text_emphasis-medium___89Lh {
  font-weight: var(--font-weight--medium) !important; }

.inline-text_emphasis-strong__1lCGP {
  font-weight: var(--font-weight--bold) !important; }

.inline-text_inline-text__12oQl {
  font-family: var(--font-family--default);
  font-size: var(--font-size--small);
  font-weight: var(--font-weight--normal);
  color: var(--color--text--static--body);
  display: inline;
  line-height: var(--line-height--medium); }
