.external-html_line-height-single-line__16OLY {
  line-height: var(--line-height--reset) !important; }

.external-html_alignment-left__1YA_C {
  text-align: left !important; }

.external-html_alignment-center__1WpBO {
  text-align: center !important; }

.external-html_alignment-right__x3-os {
  text-align: right !important; }

.external-html_size-x-small__2Nv4R {
  font-size: var(--font-size--x-small) !important; }
  .external-html_size-x-small__2Nv4R:not(.external-html_line-height-single-line__16OLY) {
    line-height: var(--line-height--small) !important; }

.external-html_size-small__si2fO {
  font-size: var(--font-size--small) !important; }
  .external-html_size-small__si2fO:not(.external-html_line-height-single-line__16OLY) {
    line-height: var(--line-height--medium) !important; }

.external-html_size-medium__1y9Xs {
  font-size: var(--font-size--medium) !important; }
  .external-html_size-medium__1y9Xs:not(.external-html_line-height-single-line__16OLY) {
    line-height: var(--line-height--large) !important; }

.external-html_size-large__3lvVU {
  font-size: var(--font-size--large) !important; }
  .external-html_size-large__3lvVU:not(.external-html_line-height-single-line__16OLY) {
    line-height: var(--line-height--medium) !important; }

.external-html_size-x-large__2CnJf {
  font-size: var(--font-size--x-large) !important; }
  .external-html_size-x-large__2CnJf:not(.external-html_line-height-single-line__16OLY) {
    line-height: var(--line-height--medium) !important; }

.external-html_size-xx-large__5eB_S {
  font-size: var(--font-size--xx-large) !important; }
  .external-html_size-xx-large__5eB_S:not(.external-html_line-height-single-line__16OLY) {
    line-height: var(--line-height--medium) !important; }

.external-html_size-xxx-large__2p58- {
  font-size: var(--font-size--xxx-large) !important; }
  .external-html_size-xxx-large__2p58-:not(.external-html_line-height-single-line__16OLY) {
    line-height: var(--line-height--small) !important; }

.external-html_tone-neutral-dark__qTnWo {
  color: var(--color--gray--darker) !important; }

.external-html_tone-neutral-light__2P6X9 {
  color: var(--color--white) !important; }

.external-html_tone-subtle__2HNPM {
  color: var(--color--typography--tone--subtle) !important; }

.external-html_tone-positive__cboSe {
  color: var(--color--typography--tone--positive) !important; }

.external-html_tone-negative__XNlJs {
  color: var(--color--typography--tone--negative) !important; }

.external-html_tone-warning__23ZwK {
  color: var(--color--typography--tone--warning) !important; }

.external-html_emphasis-normal__26H1G {
  font-weight: var(--font-weight--normal) !important; }

.external-html_emphasis-medium__1ypPK {
  font-weight: var(--font-weight--medium) !important; }

.external-html_emphasis-strong__1TICO {
  font-weight: var(--font-weight--bold) !important; }

.external-html_h1___86Ij {
  font-family: var(--font-family--heading);
  word-break: break-word;
  margin: 0;
  text-align: left;
  line-height: var(--line-height--reset);
  font-size: var(--font-size--xxx-large);
  font-weight: var(--font-weight--medium);
  color: var(--color--text--static--header); }
  .external-html_h1___86Ij:not(.external-html_line-height-single-line__16OLY) {
    line-height: var(--line-height--x-small); }

.typography-text-container > .external-html_h1___86Ij {
  margin-bottom: var(--space--xxx-large); }

.typography-text-container.typography-article-container > .external-html_h1___86Ij {
  font-weight: var(--font-weight--bold); }

.external-html_h2__2j1U1 {
  font-family: var(--font-family--heading);
  word-break: break-word;
  margin: 0;
  text-align: left;
  line-height: var(--line-height--reset);
  font-size: var(--font-size--large);
  font-weight: var(--font-weight--bold);
  color: var(--color--text--static--header); }
  .external-html_h2__2j1U1:not(.external-html_line-height-single-line__16OLY) {
    line-height: var(--line-height--small); }

.typography-text-container > .external-html_h2__2j1U1 {
  font-size: var(--font-size--x-large);
  margin-top: var(--space--xxx-large);
  margin-bottom: var(--space--medium); }

.typography-text-container.typography-article-container > .external-html_h2__2j1U1 {
  font-size: var(--font-size--xx-large);
  margin-bottom: var(--space--large); }

.external-html_h3__1UGqs {
  font-family: var(--font-family--heading);
  word-break: break-word;
  margin: 0;
  text-align: left;
  line-height: var(--line-height--reset);
  font-size: var(--font-size--large);
  font-weight: var(--font-weight--medium);
  color: var(--color--text--static--header); }
  .external-html_h3__1UGqs:not(.external-html_line-height-single-line__16OLY) {
    line-height: var(--line-height--small); }

.typography-text-container > .external-html_h3__1UGqs {
  margin-top: var(--space--medium);
  margin-bottom: var(--space--small); }

.typography-text-container.typography-article-container > .external-html_h3__1UGqs {
  font-size: var(--font-size--x-large);
  margin-top: var(--space--x-large);
  margin-bottom: var(--space--medium); }

.external-html_h4__3l_3V {
  font-family: var(--font-family--heading);
  word-break: break-word;
  margin: 0;
  text-align: left;
  line-height: var(--line-height--reset);
  font-size: var(--font-size--medium);
  font-weight: var(--font-weight--medium);
  color: var(--color--text--static--header); }
  .external-html_h4__3l_3V:not(.external-html_line-height-single-line__16OLY) {
    line-height: var(--line-height--medium); }

.typography-text-container > .external-html_h4__3l_3V {
  line-height: var(--line-height--medium);
  margin-top: var(--space--medium);
  margin-bottom: var(--space--xx-small); }

.typography-text-container.typography-article-container > .external-html_h4__3l_3V {
  font-size: var(--font-size--large);
  margin-top: var(--space--large);
  margin-bottom: var(--space--x-small); }

.external-html_h5__1dFuN {
  font-family: var(--font-family--heading);
  word-break: break-word;
  margin: 0;
  text-align: left;
  line-height: var(--line-height--reset);
  font-size: var(--font-size--small);
  font-weight: var(--font-weight--normal);
  color: var(--color--text--static--header);
  letter-spacing: 0.0625rem;
  text-transform: uppercase; }
  .external-html_h5__1dFuN:not(.external-html_line-height-single-line__16OLY) {
    line-height: var(--line-height--small); }

.typography-text-container > .external-html_h5__1dFuN {
  line-height: var(--line-height--small);
  margin-top: var(--space--small);
  margin-bottom: var(--space--xx-small); }

.typography-text-container.typography-article-container > .external-html_h5__1dFuN {
  font-size: var(--font-size--medium);
  margin-top: var(--space--medium);
  margin-bottom: var(--space--xx-small); }

.external-html_h6__2H6gc {
  font-family: var(--font-family--heading);
  word-break: break-word;
  margin: 0;
  text-align: left;
  line-height: var(--line-height--reset);
  font-size: var(--font-size--small);
  font-weight: var(--font-weight--bold);
  color: var(--color--text--static--header);
  font-family: var(--font-family--default); }
  .external-html_h6__2H6gc:not(.external-html_line-height-single-line__16OLY) {
    line-height: var(--line-height--medium); }

.typography-text-container > .external-html_h6__2H6gc {
  line-height: var(--line-height--medium);
  margin-top: var(--space--small);
  margin-bottom: var(--space--xx-small); }

.typography-text-container.typography-article-container > .external-html_h6__2H6gc {
  font-size: var(--font-size--medium);
  line-height: var(--line-height--large);
  margin-top: var(--space--medium);
  margin-bottom: var(--space--xx-small); }

.typography-text-container > .external-html_h1___86Ij:first-child,
.typography-text-container > .external-html_h2__2j1U1:first-child,
.typography-text-container > .external-html_h3__1UGqs:first-child,
.typography-text-container > .external-html_h4__3l_3V:first-child,
.typography-text-container > .external-html_h5__1dFuN:first-child,
.typography-text-container > .external-html_h6__2H6gc:first-child,
.typography-text-container.typography-article-container > .external-html_h1___86Ij:first-child,
.typography-text-container.typography-article-container > .external-html_h2__2j1U1:first-child,
.typography-text-container.typography-article-container > .external-html_h3__1UGqs:first-child,
.typography-text-container.typography-article-container > .external-html_h4__3l_3V:first-child,
.typography-text-container.typography-article-container > .external-html_h5__1dFuN:first-child,
.typography-text-container.typography-article-container > .external-html_h6__2H6gc:first-child {
  margin-top: 0; }

.external-html_ol__1Yt1_ {
  font-family: var(--font-family--default);
  font-size: var(--font-size--small);
  font-weight: var(--font-weight--normal);
  color: var(--color--text--static--body);
  line-height: var(--line-height--medium);
  padding: 0;
  margin: 0;
  list-style: outside; }

.typography-text-container > .external-html_ol__1Yt1_,
.typography-text-container > .external-html_ol__1Yt1_ > li > .external-html_ol__1Yt1_,
.typography-text-container > .external-html_ol__1Yt1_ > li > .external-html_ul__3HUll {
  margin-top: var(--space--x-small);
  margin-bottom: var(--space--small); }

.typography-text-container > .external-html_ol__1Yt1_:first-child {
  margin-top: 0; }

.typography-text-container.typography-article-container > .external-html_ol__1Yt1_,
.typography-text-container.typography-article-container > .external-html_ol__1Yt1_ > li > .external-html_ol__1Yt1_,
.typography-text-container.typography-article-container > .external-html_ol__1Yt1_ > li > .external-html_ul__3HUll {
  font-size: var(--font-size--medium);
  line-height: var(--line-height--large);
  margin-top: var(--space--small);
  margin-bottom: var(--space--medium); }

.typography-text-container.typography-article-container > .external-html_ol__1Yt1_:first-child {
  margin-top: 0; }

.external-html_ol__1Yt1_ {
  list-style-type: decimal; }
  .external-html_ol__1Yt1_ .external-html_li__MbRBt {
    margin: var(--space--xx-small);
    margin-left: var(--space--medium); }

.external-html_ul__3HUll {
  font-family: var(--font-family--default);
  font-size: var(--font-size--small);
  font-weight: var(--font-weight--normal);
  color: var(--color--text--static--body);
  line-height: var(--line-height--medium);
  padding: 0;
  margin: 0;
  list-style: outside; }

.typography-text-container > .external-html_ul__3HUll,
.typography-text-container > .external-html_ul__3HUll > li > .external-html_ul__3HUll,
.typography-text-container > .external-html_ul__3HUll > li > .external-html_ol__1Yt1_ {
  margin-top: var(--space--x-small);
  margin-bottom: var(--space--small); }

.typography-text-container > .external-html_ul__3HUll:first-child {
  margin-top: 0; }

.typography-text-container.typography-article-container > .external-html_ul__3HUll,
.typography-text-container.typography-article-container > .external-html_ul__3HUll > li > .external-html_ul__3HUll,
.typography-text-container.typography-article-container > .external-html_ul__3HUll > li > .external-html_ol__1Yt1_ {
  font-size: var(--font-size--medium);
  line-height: var(--line-height--large);
  margin-top: var(--space--small);
  margin-bottom: var(--space--medium); }

.typography-text-container.typography-article-container > .external-html_ul__3HUll:first-child {
  margin-top: 0; }

.external-html_ul__3HUll .external-html_li__MbRBt {
  margin: var(--space--xx-small);
  margin-left: var(--space--medium); }

/*
	  This will hide the focus indicator if the element receives focus via the mouse,
	  but it will still show up on keyboard focus.
	  More information at: https://github.com/WICG/focus-visible
	*/
html .external-html_external-html__20fOq a:focus:not(:focus-visible) {
  outline: none; }

html .external-html_external-html__20fOq a::-moz-focus-inner {
  border: 0; }

.external-html_external-html__20fOq {
  display: inline-block; }
  .external-html_external-html__20fOq a {
    color: var(--color--blue--dark);
    font-size: var(--font-size--interactive--default);
    font-weight: var(--font-weight--interactive--default);
    text-decoration: underline; }
    .external-html_external-html__20fOq a:hover {
      color: var(--color--blue--darker); }
    .external-html_external-html__20fOq a:active {
      color: var(--color--blue--darkest); }
    .external-html_external-html__20fOq a:focus-visible {
      box-shadow: 0 0 0 0.125rem var(--color--blue);
      outline: none; }
  .external-html_external-html__20fOq p {
    font-family: var(--font-family--default);
    font-size: var(--font-size--small);
    font-weight: var(--font-weight--normal);
    color: var(--color--text--static--body);
    line-height: var(--line-height--medium); }
  .external-html_external-html__20fOq h1 {
    font-family: var(--font-family--heading);
    word-break: break-word;
    margin: 0;
    text-align: left;
    line-height: var(--line-height--reset);
    font-size: var(--font-size--xxx-large);
    font-weight: var(--font-weight--medium);
    color: var(--color--text--static--header); }
    .external-html_external-html__20fOq h1:not(.external-html_line-height-single-line__16OLY) {
      line-height: var(--line-height--x-small); }
  .external-html_external-html__20fOq h2 {
    font-family: var(--font-family--heading);
    word-break: break-word;
    margin: 0;
    text-align: left;
    line-height: var(--line-height--reset);
    font-size: var(--font-size--large);
    font-weight: var(--font-weight--bold);
    color: var(--color--text--static--header); }
    .external-html_external-html__20fOq h2:not(.external-html_line-height-single-line__16OLY) {
      line-height: var(--line-height--small); }
  .external-html_external-html__20fOq h3 {
    font-family: var(--font-family--heading);
    word-break: break-word;
    margin: 0;
    text-align: left;
    line-height: var(--line-height--reset);
    font-size: var(--font-size--large);
    font-weight: var(--font-weight--medium);
    color: var(--color--text--static--header); }
    .external-html_external-html__20fOq h3:not(.external-html_line-height-single-line__16OLY) {
      line-height: var(--line-height--small); }
  .external-html_external-html__20fOq h4 {
    font-family: var(--font-family--heading);
    word-break: break-word;
    margin: 0;
    text-align: left;
    line-height: var(--line-height--reset);
    font-size: var(--font-size--medium);
    font-weight: var(--font-weight--medium);
    color: var(--color--text--static--header); }
    .external-html_external-html__20fOq h4:not(.external-html_line-height-single-line__16OLY) {
      line-height: var(--line-height--medium); }
  .external-html_external-html__20fOq h5 {
    font-family: var(--font-family--heading);
    word-break: break-word;
    margin: 0;
    text-align: left;
    line-height: var(--line-height--reset);
    font-size: var(--font-size--small);
    font-weight: var(--font-weight--normal);
    color: var(--color--text--static--header);
    letter-spacing: 0.0625rem;
    text-transform: uppercase; }
    .external-html_external-html__20fOq h5:not(.external-html_line-height-single-line__16OLY) {
      line-height: var(--line-height--small); }
  .external-html_external-html__20fOq h6 {
    font-family: var(--font-family--heading);
    word-break: break-word;
    margin: 0;
    text-align: left;
    line-height: var(--line-height--reset);
    font-size: var(--font-size--small);
    font-weight: var(--font-weight--bold);
    color: var(--color--text--static--header);
    font-family: var(--font-family--default); }
    .external-html_external-html__20fOq h6:not(.external-html_line-height-single-line__16OLY) {
      line-height: var(--line-height--medium); }
  .external-html_external-html__20fOq ol {
    font-family: var(--font-family--default);
    font-size: var(--font-size--small);
    font-weight: var(--font-weight--normal);
    color: var(--color--text--static--body);
    line-height: var(--line-height--medium);
    padding: 0;
    margin: 0;
    list-style: outside; }
  .external-html_external-html__20fOq .typography-text-container > ol,
  .external-html_external-html__20fOq .typography-text-container > ol > li > ol,
  .external-html_external-html__20fOq .typography-text-container > ol > li > ul {
    margin-top: var(--space--x-small);
    margin-bottom: var(--space--small); }
  .external-html_external-html__20fOq .typography-text-container > ol:first-child {
    margin-top: 0; }
  .external-html_external-html__20fOq .typography-text-container.typography-article-container > ol,
  .external-html_external-html__20fOq .typography-text-container.typography-article-container > ol > li > ol,
  .external-html_external-html__20fOq .typography-text-container.typography-article-container > ol > li > ul {
    font-size: var(--font-size--medium);
    line-height: var(--line-height--large);
    margin-top: var(--space--small);
    margin-bottom: var(--space--medium); }
  .external-html_external-html__20fOq .typography-text-container.typography-article-container > ol:first-child {
    margin-top: 0; }
  .external-html_external-html__20fOq ol {
    list-style-type: decimal; }
    .external-html_external-html__20fOq ol li {
      margin: var(--space--xx-small);
      margin-left: var(--space--medium); }
  .external-html_external-html__20fOq ul {
    font-family: var(--font-family--default);
    font-size: var(--font-size--small);
    font-weight: var(--font-weight--normal);
    color: var(--color--text--static--body);
    line-height: var(--line-height--medium);
    padding: 0;
    margin: 0;
    list-style: outside; }
  .external-html_external-html__20fOq .typography-text-container > ul,
  .external-html_external-html__20fOq .typography-text-container > ul > li > ul,
  .external-html_external-html__20fOq .typography-text-container > ul > li > ol {
    margin-top: var(--space--x-small);
    margin-bottom: var(--space--small); }
  .external-html_external-html__20fOq .typography-text-container > ul:first-child {
    margin-top: 0; }
  .external-html_external-html__20fOq .typography-text-container.typography-article-container > ul,
  .external-html_external-html__20fOq .typography-text-container.typography-article-container > ul > li > ul,
  .external-html_external-html__20fOq .typography-text-container.typography-article-container > ul > li > ol {
    font-size: var(--font-size--medium);
    line-height: var(--line-height--large);
    margin-top: var(--space--small);
    margin-bottom: var(--space--medium); }
  .external-html_external-html__20fOq .typography-text-container.typography-article-container > ul:first-child {
    margin-top: 0; }
  .external-html_external-html__20fOq ul li {
    margin: var(--space--xx-small);
    margin-left: var(--space--medium); }

.typography-text-container .external-html_external-html__20fOq > p {
  line-height: var(--line-height--medium); }
  .typography-text-container .external-html_external-html__20fOq > p + p {
    margin-top: var(--space--small); }

.typography-text-container .external-html_external-html__20fOq > h1 {
  margin-bottom: var(--space--xxx-large); }

.typography-text-container .external-html_external-html__20fOq > h2 {
  font-size: var(--font-size--x-large);
  margin-top: var(--space--xxx-large);
  margin-bottom: var(--space--medium); }

.typography-text-container .external-html_external-html__20fOq > h3 {
  margin-top: var(--space--medium);
  margin-bottom: var(--space--small); }

.typography-text-container .external-html_external-html__20fOq > h4 {
  line-height: var(--line-height--medium);
  margin-top: var(--space--medium);
  margin-bottom: var(--space--xx-small); }

.typography-text-container .external-html_external-html__20fOq > h5 {
  line-height: var(--line-height--small);
  margin-top: var(--space--small);
  margin-bottom: var(--space--xx-small); }

.typography-text-container .external-html_external-html__20fOq > h6 {
  line-height: var(--line-height--medium);
  margin-top: var(--space--small);
  margin-bottom: var(--space--xx-small); }

.typography-text-container.typography-article-container .external-html_external-html__20fOq > p {
  font-size: var(--font-size--medium);
  line-height: var(--line-height--large); }
  .typography-text-container.typography-article-container .external-html_external-html__20fOq > p + p {
    margin-top: var(--space--medium); }

.typography-text-container.typography-article-container .external-html_external-html__20fOq > h1 {
  font-weight: var(--font-weight--bold); }

.typography-text-container.typography-article-container .external-html_external-html__20fOq > h2 {
  font-size: var(--font-size--xx-large);
  margin-bottom: var(--space--large); }

.typography-text-container.typography-article-container .external-html_external-html__20fOq > h3 {
  font-size: var(--font-size--x-large);
  margin-top: var(--space--x-large);
  margin-bottom: var(--space--medium); }

.typography-text-container.typography-article-container .external-html_external-html__20fOq > h4 {
  font-size: var(--font-size--large);
  margin-top: var(--space--large);
  margin-bottom: var(--space--x-small); }

.typography-text-container.typography-article-container .external-html_external-html__20fOq > h5 {
  font-size: var(--font-size--medium);
  margin-top: var(--space--medium);
  margin-bottom: var(--space--xx-small); }

.typography-text-container.typography-article-container .external-html_external-html__20fOq > h6 {
  font-size: var(--font-size--medium);
  line-height: var(--line-height--large);
  margin-top: var(--space--medium);
  margin-bottom: var(--space--xx-small); }

.typography-text-container.typography-article-container .external-html_external-html__20fOq > h1:first-child,
.typography-text-container.typography-article-container .external-html_external-html__20fOq > h2:first-child,
.typography-text-container.typography-article-container .external-html_external-html__20fOq > h3:first-child,
.typography-text-container.typography-article-container .external-html_external-html__20fOq > h4:first-child,
.typography-text-container.typography-article-container .external-html_external-html__20fOq > h5:first-child,
.typography-text-container.typography-article-container .external-html_external-html__20fOq > h6:first-child,
.typography-text-container.typography-article-container .external-html_external-html__20fOq > ol:first-child,
.typography-text-container.typography-article-container .external-html_external-html__20fOq > ul:first-child {
  margin-top: 0; }
